import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { WorkModel } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkService {

  #http = inject(HttpClient);

  getWorks(): Observable<WorkModel[]> {
    return this.#http.get<WorkModel[]>('assets/works/works.json')
  }
}
